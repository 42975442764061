<template>
  <div class="w-screen bg-gray-50 h-screen justify-center items-center">
    <div class="text-center p-4 py-5 bg-white shadow-sm">
      <img src="@/assets/logo.png" class="mx-auto" />
    </div>
    <div class="mt-16 font-normal text-md text-gray-500">Código da compra</div>
    <div class="">
      <div class="mx-auto mt-3">
        <input
          type="text"
          class="w-2/3 sm:w-3/6 max-w-md px-3 py-2 rounded-md bg-white uppercase text-center text-gray-900 placeholder-gray-500 focus:outline-none focus:bg-white focus:placeholder-gray-400 shadow-sm"
          v-mask="'AAAAA'"
          v-model="codigo"
          :disabled="loading"
          :class="{ 'ring-2 ring-red-200': !valid.code }"
          placeholder="Código"
          @keyup.backspace="() => {}"
        />
        <div>
          <div
            class="text-teal-700 font-light py-3 px-4 rounded-md inline-block mx-auto mt-3"
            :class="{
              'bg-red-50 text-red-700': !valid.code,
              'bg-blue-50 text-blue-900': valid.code,
            }"
          >
            Digite as 5 letras do código
          </div>
        </div>
      </div>
    </div>
    <div class="mt-11 p-4">
      <div class="text-center mx-auto">
        <button
          :disabled="loading"
          class="text-white py-3 px-6 rounded-md"
          :class="{
            'bg-gray-200 hover:bg-gray-100': loading,
            'bg-pink-600 hover:bg-pink-500': !loading,
          }"
          @click="confirmCode"
        >
          Confirmar
        </button>
      </div>
    </div>
    <v-modal v-if="modal">
      {{ modalMensagem }}
      <div class="mt-3">
        <button
          class="text-white py-3 px-6 rounded-md focus:bg-pink-500"
          :class="{ 'bg-gray-100': loading, 'bg-pink-600': !loading }"
          @click="modal = false"
        >
          Ok
        </button>
      </div>
    </v-modal>
  </div>
</template>

<script>
import { requestCode } from "@/service/firebase.js";
export default {
  data() {
    return {
      loading: false,
      modal: false,
      modalMensagem: "",
      codigo: "",
      valid: {
        code: true,
      },
    };
  },
  methods: {
    async confirmCode() {
      this.loading = true;
      const code = this.codigo.toUpperCase();
      if (code && code.length === 5) {
        this.valid.code = true;
        let bin = await requestCode(code);
        if (bin) {
          const idCompra = bin.compra_id;
          // ? necessario ? localStorage.setItem("codigo", code);
          this.$router.push("/init/" + idCompra);
        } else {
          this.modalMensagem = "Código inválido";
          this.modal = true;
          this.loading = false;
        }
        this.loading = false;
      } else {
        this.valid.code = false;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputCode {
  @apply p-4
            inline-block
            w-16
            shadow-md
            m-1
            text-center text-2xl
            font-bold uppercase;
}
</style>
