var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-screen bg-gray-50 h-screen justify-center items-center"},[_vm._m(0),_c('div',{staticClass:"mt-16 font-normal text-md text-gray-500"},[_vm._v("Código da compra")]),_c('div',{},[_c('div',{staticClass:"mx-auto mt-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('AAAAA'),expression:"'AAAAA'"},{name:"model",rawName:"v-model",value:(_vm.codigo),expression:"codigo"}],staticClass:"w-2/3 sm:w-3/6 max-w-md px-3 py-2 rounded-md bg-white uppercase text-center text-gray-900 placeholder-gray-500 focus:outline-none focus:bg-white focus:placeholder-gray-400 shadow-sm",class:{ 'ring-2 ring-red-200': !_vm.valid.code },attrs:{"type":"text","disabled":_vm.loading,"placeholder":"Código"},domProps:{"value":(_vm.codigo)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return (() => {}).apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.codigo=$event.target.value}}}),_c('div',[_c('div',{staticClass:"text-teal-700 font-light py-3 px-4 rounded-md inline-block mx-auto mt-3",class:{
            'bg-red-50 text-red-700': !_vm.valid.code,
            'bg-blue-50 text-blue-900': _vm.valid.code,
          }},[_vm._v(" Digite as 5 letras do código ")])])])]),_c('div',{staticClass:"mt-11 p-4"},[_c('div',{staticClass:"text-center mx-auto"},[_c('button',{staticClass:"text-white py-3 px-6 rounded-md",class:{
          'bg-gray-200 hover:bg-gray-100': _vm.loading,
          'bg-pink-600 hover:bg-pink-500': !_vm.loading,
        },attrs:{"disabled":_vm.loading},on:{"click":_vm.confirmCode}},[_vm._v(" Confirmar ")])])]),(_vm.modal)?_c('v-modal',[_vm._v(" "+_vm._s(_vm.modalMensagem)+" "),_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"text-white py-3 px-6 rounded-md focus:bg-pink-500",class:{ 'bg-gray-100': _vm.loading, 'bg-pink-600': !_vm.loading },on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Ok ")])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center p-4 py-5 bg-white shadow-sm"},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/logo.png")}})])
}]

export { render, staticRenderFns }